export const DASHBOARD_PATH = "/"; //! This path is accepted if there is a user
export const LOGIN_PATH = "/"; //! check user
export const CATEGORY_PATH = "/kategoriyalar";
export const CATALOGS_PATH = "/kataloglar";
export const AUTH_PATH = "/akkountlar";
export const SLIDR_IMAGES_PATH = "/mobil-dastur-sliderlari";
export const PRODUCTS_PATH = "/mahsulotlar";
export const PRODUCTION_PATH = "/ishlab-chiqarish-bo'limlari";
export const BEST_SELLING_PRODUCTS_PATH = "/top-mahsulotlar"
export const CHECK_PRODUCT_ORDER_PATH = "/qabul-qilingan-buyurtma";
export const PROGRESS_AND_READY_ORDER_PATH = "/tayyorlanayotgan-qilingan-buyurtma";
export const PENDING_ORDER_PATH = "/kutilayotgan-buyurtma";
export const WARE_HOUSE_ORDER_PATH = "/ishlab-chiqarish-bo'limiga-buyurtma";
export const WARE_HOUSE_ORDER_CHILD_PATH = "/ishlab-chiqarish-bo'limiga-buyurtma-berish";
export const COMPLETED_CLIENT_ORDER_PATH = "/yakunlangan-buyurtma";
export const CLIENTS_PATH = "/mijozlar";
export const CLIENTS_CHILD_PATH = "/mijoz-malumotlari";
export const NEW_ORDERS_WEB_PATH = "/yangi-buyurtmalar";
export const CANCELED_ORDERS_WEB_PATH = "/bekor-qilingan-buyurtmalar";
export const READY_ORDERS_WEB_PATH = "/tayyor-buyurtmalar";
export const PAYMENT_ORDERS_WEB_INFO_PATH = "/to'lov-qilingan-buyurtma";
export const PAYMENT_ORDERS_WEB_PATH = "/to'lov-qilingan-buyurtmalar";
export const COMPLETED_ORDERS_WEB_PATH = "/yakunlangan-buyurtmalar";
export const PENDING_ORDERS_WEB_PATH = "/kutilayotgan-buyurtmalar";
export const PROGRESS_ORDERS_WEB_PATH = "/jarayondagi-buyurtmalar";
export const PARTIALLY_ORDERS_WEB_PATH = "/qisman-yuklangan-buyurtmalar";
export const PARTIALLY_ORDER_CHILD_PATH = "/qisman-yuklangan-buyurtma";
export const PRODUCT_WAREHOUSE_COUNT_PATH = "/ombordagi-mahsulotlar-soni"
export const NEW_CLIENT_ORDER_PATH = "/mijozga-qo'shimcha-buyurtma-berish"



 