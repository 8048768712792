export const INFORMATIONS_PAGE = "INFORMATIONS_PAGE";
export const DASHBOARD_PAGE = "DASHBOARD_PAGE";
export const CHECK_PRODUCT_ORDER_PAGE = "CHECK_PRODUCT_ORDER_PAGE";
export const ORDER_WEB_PAGE = "ORDER_WEB_PAGE";
export const BEST_SELLING_PRODUCTS_PAGE = "BEST_SELLING_PRODUCTS_PAGE";
export const WARE_HOUSE_ORDER_PAGE = "WARE_HOUSE_ORDER_PAGE";
export const WARE_HOUSE_ORDER_CHILD_PAGE = "WARE_HOUSE_ORDER_CHILD_PAGE";
export const PAYMENT_ORDERS_WEB_PAGE = "PAYMENT_ORDERS_WEB_PAGE";
export const PARTIALLY_ORDER_CHILD_PAGE = "PARTIALLY_ORDER_CHILD_PAGE";
export const COMPLETED_CLIENT_ORDER_PAGE = "COMPLETED_CLIENT_ORDER_PAGE";
export const SLIDER_APP_PAGE = "SLIDER_APP_PAGE";
export const CLIENTES_PAGE = "CLIENTES_PAGE";
export const CLIENTES_CHILD_PAGE = "CLIENTES_CHILD_PAGE";
export const PRODUCT_WAREHOUSE_COUNT_PAGE = "PRODUCT_WAREHOUSE_COUNT_PAGE";
export const INFORMATIONS_GET_PAGE = "INFORMATIONS_GET_PAGE";
export const NEW_CLIENT_ORDER_PAGE = "NEW_CLIENT_ORDER_PAGE";
