import React from "react";
import { user } from "./constants";
import "./styles/style.scss";
const Login = React.lazy( () => import("./pages/Login/login"))
const Latout = React.lazy( () => import("./layout/index"))


function App() {
  return (user?.user_id && user?.role && user?.token) ? <Latout /> : <Login />
}

export default App;

