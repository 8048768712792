import env from "react-dotenv";

export const CATEGORY_URL = env.CATEGORY_URL
export const CATALOGS_URL = env.CATALOGS_URL
export const PRODUCTION_URL = env.PRODUCTION_URL
export const BEST_SELLING_PRODUCTS_URL = env.BEST_SELLING_PRODUCTS_URL
export const PRODUCTS_URL = env.PRODUCTS_URL
export const CHECK_PRODUCT_ORDER_URL = env.CHECK_PRODUCT_ORDER_URL
export const AUTH_URL = env.AUTH_URL
export const WARE_HOUSE_ORDER_URL = env.WARE_HOUSE_ORDER_URL
export const WARE_HOUSE_ORDER_CHILD_URL = env.WARE_HOUSE_ORDER_CHILD_URL
export const COLLECTORS_URL = env.COLLECTORS_URL
export const SLIDER_APP_URL = env.SLIDER_APP_URL
export const CLIENTS_URL = env.CLIENTS_URL
export const DELECT_URL = env.DELECT_URL
export const ORDER_CHECK_UPDATE_PEREPARE_LIST_URL = env.ORDER_CHECK_UPDATE_PEREPARE_LIST_URL
export const WEB_ORDER_CHECK_URL = env.WEB_ORDER_CHECK_URL
export const WEB_ORDER_CHECK_UPDATE_URL = env.WEB_ORDER_CHECK_UPDATE_URL
export const CLIENT_SHOW_URL = env.CLIENT_SHOW_URL
export const COLLECTOR_ORDER_LOAD_READY_URL = env.COLLECTOR_ORDER_LOAD_READY_URL
export const WEB_ORDERS_URL = env.WEB_ORDERS_URL
export const WEB_ORDERS_SEARCH_FILTERS_URL = env.WEB_ORDERS_SEARCH_FILTERS_URL
export const SLIDER_PHOTO_UPLOAD_URL = env.SLIDER_PHOTO_UPLOAD_URL
export const SLIDER_PHOTO_DELETE_URL = env.SLIDER_PHOTO_DELETE_URL
export const WAREHOUSE_ORDER_URL = env.WAREHOUSE_ORDER_URL
export const WEB_ORDER_CHECK_PRODUCT_COUNT_URL = env.WEB_ORDER_CHECK_PRODUCT_COUNT_URL
export const WAREHOUSE_ORDER_LIST_DELETE_URL = env.WAREHOUSE_ORDER_LIST_DELETE_URL
export const CHECK_BEST_SELLING_PRODUCT_URL = env.CHECK_BEST_SELLING_PRODUCT_URL
export const PRODUCT_FILTER_SEARCH_URL = env.PRODUCT_FILTER_SEARCH_URL
export const UNCHECK_BEST_SELLING_PRODUCT_UPDATE_URL =  env.UNCHECK_BEST_SELLING_PRODUCT_UPDATE_URL
export const PRODUCT_CATEGORY_IMAGE_STORE_URL = env.PRODUCT_CATEGORY_IMAGE_STORE_URL
export const CATEGORY_DELETE_IMAHE_URL = env.CATEGORY_DELETE_IMAHE_URL
export const PRODUCTION_TYPE_DELETE_URL = env.PRODUCTION_TYPE_DELETE_URL
export const PRODUCTION_TYPE_SEARCH_URL = env.PRODUCTION_TYPE_SEARCH_URL
export const C_PRODUCTS_SEARCH_URL = env.C_PRODUCTS_SEARCH_URL
export const PRODUCTS_IMAGE_STORE_URL = env.PRODUCTS_IMAGE_STORE_URL
export const PRODUCT_DELETE_IMAGE_URL = env.PRODUCT_DELETE_IMAGE_URL
export const PRODUCT_DELET_IMAGE_URL = env.PRODUCT_DELET_IMAGE_URL
export const WEB_ORDER_SEARCH_FILTER_PAGE_URL = env.WEB_ORDER_SEARCH_FILTER_PAGE_URL
export const PRODUCTS_SEARCH_FILTER_URL = env.PRODUCTS_SEARCH_FILTER_URL
export const REPORT_DASHBOARD_BEST_SALES_PRODUCT_COUNT_URL = env.REPORT_DASHBOARD_BEST_SALES_PRODUCT_COUNT_URL
export const REPORT_DASHBOARD_ORDER_COUNT_URL = env.REPORT_DASHBOARD_ORDER_COUNT_URL
export const LOGIN_EMPLOYEE_URL = env.LOGIN_EMPLOYEE_URL
export const REPORT_DASHBOARD_CARDS_URL = env.REPORT_DASHBOARD_CARDS_URL
export const EMPLOYE_REG_ALL = env.EMPLOYE_REG_ALL
export const PROD_CATE_SEARCH = env.PROD_CATE_SEARCH
export const EMPL_UPD_PASS_URL = env.EMPL_UPD_PASS_URL
export const CLINET_SEARCH_URL = env.CLINET_SEARCH_URL
export const PRODUCT_WAREHOUSE_COUNT_URL = "/nimadur"
export const CATALOGS_IMAGE_STORE_URL = env.CATALOGS_IMAGE_STORE_URL
export const CATALOGS_IMAGE_DELETE_URL = env.CATALOGS_IMAGE_DELETE_URL




