export const STRING = "STRING";
export const SELECT = "SELECT";
export const DATE = "DATE";
export const NUMBER_FORMAT = "NUMBER_FORMAT";
export const NUMBER = "NUMBER";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const FILTERABLE_TABLE_INPUT = "FILTERABLE_TABLE_INPUT";
export const PASSWORD = "PASSWORD";
export const SWETCH_PRODUCTION_BY_ROLE ="SWETCH_PRODUCTION_BY_ROLE";
export const PERIOD_RANGE_DATE ="PERIOD_RANGE_DATE";
export const PHONE = "PHONE"



