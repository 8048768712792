import React, { Suspense } from "react";
import App from "./App";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.min.css";
import ruRu from "antd/lib/locale/ru_RU";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { persistStore } from "redux-persist";
import { store } from "./store";
import Loading from "./components/Loading/index";

const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
  <Suspense fallback={<Loading/>}>
    <ConfigProvider locale={ruRu}>
      <Provider store={store}>
        <PersistGate  loading={<Loading />} persistor={persistor}>
            <Router>
              <App />
            </Router>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </Suspense>
  // </React.StrictMode> 
);
reportWebVitals();

