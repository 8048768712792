import { createSlice } from "@reduxjs/toolkit";

export const currentPageReducer = createSlice({
  name: "currentpageReducer",
  initialState: {
    currentPage: {},
    filterableTableModal:{},
    filterableClientTableModal:{},
    filterableAddProductOrderTableModal:{},
    changePasswordIsOpenModal:false,
    countProductOrder:{},
    pendingOrderEditCountModal:{},
  },
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = { ...payload };
    },
    toggleModal_CurrentPage: (state, { payload }) => {
      state.currentPage.isOpenModal = payload;
    },
    setFilteraTablePage: (state, { payload }) => {
      state.filterableTableModal = payload;
    },
    setFilteraClientTablePage: (state, { payload }) => {
      state.filterableClientTableModal = payload;
    },
    setFilteraAddProductOrderTablePage: (state, { payload }) => {
      state.filterableAddProductOrderTableModal = payload;
    },
    setCountProductOrder: (state, { payload }) => {
      state.countProductOrder = payload
    },
    setPendingOrderEditModal:(state, {payload}) => {
      state.pendingOrderEditCountModal = payload
    }, 
    toggleChangePasswordModal: (state, { payload }) => {
      state.changePasswordIsOpenModal = payload;
    }
  },
});

export const {
  setCurrentPage,
  toggleModal_CurrentPage,
  setFilteraTablePage,
  toggleChangePasswordModal,
  setFilteraClientTablePage,
  setFilteraAddProductOrderTablePage,
  setCountProductOrder,
  setPendingOrderEditModal,
} = currentPageReducer.actions;

export default currentPageReducer.reducer;
