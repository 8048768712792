import { NUMBER, STRING } from "./inputTypes";
import { COLLECTORS_URL } from "./pageUrls";

export {
  STRING,
  DATE,
  SELECT,
  NUMBER_FORMAT,
  NUMBER,
  UPLOAD_IMAGE,
  PASSWORD,
  SWETCH_PRODUCTION_BY_ROLE,
  FILTERABLE_TABLE_INPUT,
  PERIOD_RANGE_DATE,
  PHONE,
} from "./inputTypes";

export const INFORMATIONS_MODAL = "INFORMATIONS_MODAL";
export const CHECK_PRODUCT_ORDER_MODAL = "CHECK_PRODUCT_ORDER_MODAL";
export const WARE_HOUSE_ORDER_MODAL = "WARE_HOUSE_ORDER_MODAL";
export const PRODUCT_ADD_COUNT_MODAL = "PRODUCT_ADD_COUNT_MODAL";

export {
  DASHBOARD_PATH,
  CATEGORY_PATH,
  PRODUCTS_PATH,
  NEW_ORDERS_WEB_PATH,
  CHECK_PRODUCT_ORDER_PATH,
  PRODUCTION_PATH,
  AUTH_PATH,
  BEST_SELLING_PRODUCTS_PATH,
  WARE_HOUSE_ORDER_PATH,
  WARE_HOUSE_ORDER_CHILD_PATH,
  READY_ORDERS_WEB_PATH,
  CANCELED_ORDERS_WEB_PATH,
  PAYMENT_ORDERS_WEB_PATH,
  PARTIALLY_ORDERS_WEB_PATH,
  COMPLETED_ORDERS_WEB_PATH,
  PENDING_ORDERS_WEB_PATH,
  PROGRESS_ORDERS_WEB_PATH,
  PAYMENT_ORDERS_WEB_INFO_PATH,
  PARTIALLY_ORDER_CHILD_PATH,
  COMPLETED_CLIENT_ORDER_PATH,
  SLIDR_IMAGES_PATH,
  CLIENTS_PATH,
  CLIENTS_CHILD_PATH,
  LOGIN_PATH,
  PRODUCT_WAREHOUSE_COUNT_PATH,
  CATALOGS_PATH,
  NEW_CLIENT_ORDER_PATH,
  PENDING_ORDER_PATH,
} from "./pagePaths";

export {
  INFORMATIONS_PAGE,
  DASHBOARD_PAGE,
  CHECK_PRODUCT_ORDER_PAGE,
  ORDER_WEB_PAGE,
  BEST_SELLING_PRODUCTS_PAGE,
  WARE_HOUSE_ORDER_PAGE,
  WARE_HOUSE_ORDER_CHILD_PAGE,
  PAYMENT_ORDERS_WEB_PAGE,
  PARTIALLY_ORDER_CHILD_PAGE,
  COMPLETED_CLIENT_ORDER_PAGE,
  SLIDER_APP_PAGE,
  CLIENTES_PAGE,
  CLIENTES_CHILD_PAGE,
  PRODUCT_WAREHOUSE_COUNT_PAGE,
  INFORMATIONS_GET_PAGE,
  NEW_CLIENT_ORDER_PAGE,
} from "./pageTypes";
export {
  CATEGORY_URL,
  PRODUCTS_URL,
  CHECK_PRODUCT_ORDER_URL,
  PRODUCTION_URL,
  AUTH_URL,
  BEST_SELLING_PRODUCTS_URL,
  WARE_HOUSE_ORDER_URL,
  WARE_HOUSE_ORDER_CHILD_URL,
  COLLECTORS_URL,
  SLIDER_APP_URL,
  CLIENTS_URL,
  DELECT_URL,
  ORDER_CHECK_UPDATE_PEREPARE_LIST_URL,
  WEB_ORDER_CHECK_URL,
  WEB_ORDER_CHECK_UPDATE_URL,
  CLIENT_SHOW_URL,
  COLLECTOR_ORDER_LOAD_READY_URL,
  WEB_ORDERS_URL,
  WEB_ORDERS_SEARCH_FILTERS_URL,
  SLIDER_PHOTO_UPLOAD_URL,
  SLIDER_PHOTO_DELETE_URL,
  WAREHOUSE_ORDER_URL,
  WEB_ORDER_CHECK_PRODUCT_COUNT_URL,
  WAREHOUSE_ORDER_LIST_DELETE_URL,
  CHECK_BEST_SELLING_PRODUCT_URL,
  PRODUCT_FILTER_SEARCH_URL,
  UNCHECK_BEST_SELLING_PRODUCT_UPDATE_URL,
  PRODUCT_CATEGORY_IMAGE_STORE_URL,
  CATEGORY_DELETE_IMAHE_URL,
  PRODUCTION_TYPE_DELETE_URL,
  PRODUCTION_TYPE_SEARCH_URL,
  C_PRODUCTS_SEARCH_URL,
  PRODUCTS_IMAGE_STORE_URL,
  PRODUCT_DELETE_IMAGE_URL,
  PRODUCT_DELET_IMAGE_URL,
  WEB_ORDER_SEARCH_FILTER_PAGE_URL,
  PRODUCTS_SEARCH_FILTER_URL,
  REPORT_DASHBOARD_BEST_SALES_PRODUCT_COUNT_URL,
  REPORT_DASHBOARD_ORDER_COUNT_URL,
  REPORT_DASHBOARD_CARDS_URL,
  LOGIN_EMPLOYEE_URL,
  EMPLOYE_REG_ALL,
  PROD_CATE_SEARCH,
  EMPL_UPD_PASS_URL,
  CLINET_SEARCH_URL,
  PRODUCT_WAREHOUSE_COUNT_URL,
  CATALOGS_URL,
  CATALOGS_IMAGE_STORE_URL,
} from "./pageUrls";

export const RIGHT_TABLE = "RIGHT_TABLE";
export const LEFT_TABLE = "LEFT_TABLE";
export const ORDER_WEB_TABLE = "ORDER_WEB_TABLE";
export const RIGHT_TABLE_BOTTOM = "RIGHT_TABLE_BOTTOM";
export const RIGHT_TABLE_TOP = "RIGHT_TABLE_TOP";
export const FILTERABLE_TABLE = "FILTERABLE_TABLE";

export const status = [
  { name: "Yangi", id: 1, color: "yellow" },
  { name: "Kutilmoqda", id: 2, color: "yellow" },
  { name: "Jarayonda", id: 3, color: "yellow" },
  { name: "Tayyor", id: 4, color: "rgb(35, 223, 35)" },
  { name: "To'lov qilingan", id: 5, color: "rgb(35, 223, 35)" },
  { name: "Qisman yakunlangan", id: 6, color: "rgb(35, 223, 35)" },
  { name: "Yakunlangan", id: 7, color: "green" },
  { name: "Bekor qilingan", id: 8, color: "red" },
];

export const c_products = "c_products";
export const collectorOption = {
  optionName: "collectorOptions",
  optionUrl: COLLECTORS_URL,
};

export const buttonStyle = {
  height: "35px",
  backgroundColor: "var(--dark-blue)",
  border: "2px solid var(--dark-blue)",
  borderRadius: "5px",
  color: "#fff",
  width: "140px",
  cursor: "pointer",
  fontSize: "13px",
};

export const loadingStyle = {
  ...buttonStyle,
  opacity: "0.5",
  cursor: "not-allowed",
};

export const dateFormat = "YYYY-MM-DD";
export const user = JSON.parse(sessionStorage.getItem("user"));
export const token = user?.token;

export const roles = ["production", "collector", "admin", "manager"];


export const orderCountModal = {
  title: "chotki",
  isOpenModal:true,
  form: {
    title: "chotki",
    modal: { width: 700 },
    gridTemplates: { columns: "repeat(8, 1fr)" },
    inputs: [
      {
        name: "product_name",
        label: "Mahsulot",
        disabled: true,
        type: STRING,
        gridColumn: "1 / 9",
        gridRow: "1 / 2",
      },
      {
        name: "propert_name",
        label: "Mahsulot tasnifi",
        disabled: true,
        type: STRING,
        gridColumn: "1 / 6",
        gridRow: "2 / 3",
      },
      {
        name: "count",
        label: "Buyurtma soni",
        required: true,
        type: NUMBER,
        gridColumn: "6 / 9",
        gridRow: "2 / 3",
        avtoFocus:true,
      }
    ],
  },
 }